<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          INVOICE No:
        </span>
      </p>
      <ul class="menu-list">
        <li
          v-for="(invoice) in invoices"
          :key="invoice.invoiceID">
          {{ invoice.invoiceRef }}
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'

export default {
  name: 'FactorInvoiceSideMenu',
  components: {
    AppSideMenu
  },
  mixins: [NumberFiltersMixin, StoreMixin],
  props: {
    entityName: {
      type: String,
      default: ''
    },
    invoices: null
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
