<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="false"
      :show-save="entity && entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <factor-invoice-side-menu v-if="entity"
          :invoices="entity.invoices"
          ref="sideMenu" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :factor-amount="amountToFactor"
          :is-tiled="false"
          :key="$route.fullPath"
          :disable-change="entity.isNew"
          :route-name="$route.name" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import FactorInvoiceSideMenu from './FactorInvoiceSideMenu'
import StoreMixin from './storeMixin'
import FactorInvoiceService from './FactorInvoiceService'
import FactorInvoiceValidation from './FactorInvoiceValidation'
import ReceiptEntryRoutes from '../receiptentry/route-types'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _debounce from 'lodash.debounce'
import HttpStatus from '@/components/http-status'
import { Emailer } from '@/classes'
//import StoreUtil from '@/store/utils'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { UnsavedModal } from '@/components/BulmaModal'
import StoreUtil from '@/store/utils'

export default {
  name: 'FactorInvoiceView',
  components: {
    AppDetailHeader,
    [FactorInvoiceSideMenu.name]: FactorInvoiceSideMenu,
    UnsavedModal
  },
  mixins: [FactorInvoiceValidation, StoreMixin],
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      disableSave: false
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    },
    amountToFactor() {
      return this.entity.invoices.reduce((acc, obj) => {
        return acc + obj.totalIncGst
      }, 0)
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  async created() {
    await this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.id)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.id)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Factor Invoice'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          this.disableSave = true
          response = await FactorInvoiceService.postEntity(this.entity)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? 'Factor Invoice deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? 'Factor Invoice deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, 'New Factor Invoice added')
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Factor Invoice', e)
        }
        return false
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|factorinvoice|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|factorinvoice|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.$route.params.readOnly) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.invoiceId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        }
      }
    },
    print() {
      const params = {
        CompanyID: this.$userInfo.companyId,
        VendorId: this.entity.vendorID,
        InvoiceID: this.entity.creditorInvoiceID,
        REPORTTITLE: 'FACTOR REMITTANCE',
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }

      const emailer = new Emailer()
      emailer.subject = 'Factor Remittance'
      emailer.reportName = 'AT_CreditorInvoice'
      this.addEmailer(emailer)
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_CreditorInvoiceV2' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.$route.params.readOnly) {
      console.log(this.snapshotDiff)
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.creditorInvoiceID)
      this.clearSessionStorage()
      this.clearRouteMeta()
      this.setReturnRoute({})
      next()
    }
  }
}
</script>