<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Factor Invoice</p>
            </div>
          </div>
          <div class="field-body">
            <div class="field">
              <label class="label">Ref No.</label>
              <div class="control">
                <input class="input is-uppercase"
                  v-model="innerValue.invoiceNo"
                  :class="{'is-danger' : $v.entity.invoiceNo.$error}"
                  :disabled="!disableChange"
                  type="text"
                  placeholder="Invoice No.">
                <span
                  class="help is-danger"
                  v-if="$v.entity && !$v.entity.invoiceNo.required">Invoice No. is required.
                </span>
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Invoice Date</label>
              <div class="control">
                <v-date-picker v-model="selectedInvoiceDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Invoice Date"
                          :disabled="!disableChange"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="is-divider" />
          <div class="field-body">
            <div class="field">
              <label class="label">Factorer</label>
              <div class="control">
                <multiselect v-if="vendors"
                  v-model="selectedVendor"
                  :disabled="!disableChange"
                  deselect-label="Can't remove this value"
                  track-by="name"
                  label="name"
                  :class="{'is-danger' : $v.entity.vendorID.$error}"
                  :show-labels="false"
                  :options="vendors"
                  :options-limit="assetFilter.pageSize"
                  :loading="isVendorLoading"
                  :internal-search="false"
                  :searchable="true"
                  :allow-empty="false"
                  @select="selectVendor"
                  @remove="removeVendor"
                  @search-change="getVendorDropdownFilter"
                  placeholder="Select a factorer">
                  <span class="has-text-danger"
                    slot="noResult">Factorer not found.</span>
                </multiselect>
                <span
                  class="help is-danger"
                  v-if="$v.entity && !$v.entity.vendorID.validGuid">Factor is required.
                </span>
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">ABN</label>
              <div class="control has-icons-right">
                <the-mask id="abn"
                  :class="{ 'is-static' : !disableChange, 'is-danger' : $v.entity.abn.$error}"
                  :disabled="!disableChange"
                  name="abn"
                  ref="abn"
                  class="input abn"
                  v-model="innerValue.abn"
                  placeholder="ABN"
                  :mask="['## ### ### ###']"
                />
                <span
                  v-if="!$v.entity.abn.$error && $v.entity.abn.validAbn && $v.entity.abn.minLength && $v.entity.abn.required"
                  class="icon has-text-success is-right">
                  <i class="mdi mdi-check mdi-18px" />
                </span>
                <span
                  class="help is-danger"
                  v-if="$v.entity && !$v.entity.abn.minLength">ABN must be at least {{ $v.entity.abn.$params.minLength.min }} letters.</span>
                <span
                  class="help is-danger"
                  v-else-if="$v.entity && !$v.entity.abn.validAbn">ABN format is invalid.</span>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label has-text-right">Amount to Factor</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    :value="balance"
                    :disabled="!disableChange"
                    type="text"
                    placeholder=""
                    :precision="2"
                    readonly />
                </div>
              </div>
              <div class="field">
                <label class="label has-text-right">Factoring %</label>
                <vue-numeric class="input has-text-right"
                  v-model="factorPercent"
                  :disabled="!disableChange"
                  @blur="calculateFactorFee()"
                  type="text"
                  placeholder=""
                  :max="100"
                  :min="0"
                  symbol="%"
                  symbol-position="suffix"
                  :precision="2" />
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label has-text-right">Net Amount</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="innerValue.netAmount"
                    :disabled="!disableChange"
                    type="text"
                    placeholder=""
                    :precision="2"
                    readonly />
                </div>
              </div>
              <div class="field">
                <label class="label has-text-right">Factor Fee</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="innerValue.totalAmount"
                    :disabled="!disableChange"
                    @blur="calculateFactorPercent()"
                    type="text"
                    placeholder=""
                    :precision="2" />
                </div>
              </div>
            </div>
          </div>
          <div class="is-divider" />
          <div class="field">
            <div class="field-body">
              <div class="field is-narrow">
                <label class="label">Cheque No.</label>
                <div class="control">
                  <input class="input is-uppercase"
                    v-model="innerValue.chequeNo"
                    type="text"
                    :disabled="!disableChange"
                    placeholder="">
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">Bank Account</label>
                <div class="control">
                  <multiselect v-if="glBankAccountListCombo"
                    v-model="innerValue.selectedBankAccount"
                    :disabled="!disableChange"
                    :options="glBankAccountListCombo"
                    :loading="isGlBankAccountLoading"
                    placeholder="Select account"
                    label="displayName"
                    deselect-label="Can't remove this value"
                    track-by="accountNo"
                    :allow-empty="false">
                    <span
                      class="has-text-danger"
                      slot="noResult">Bank account not found.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">G/L Account</label>
                <div class="control">
                  <multiselect v-if="glAccountListCombo"
                    v-model="innerValue.selectedGlAccount"
                    :disabled="!disableChange"
                    :loading="isGlAccountLoading"
                    :options="glAccountListCombo"
                    placeholder="Select account"
                    label="displayName"
                    deselect-label="Can't remove this value"
                    track-by="accountNo"
                    :allow-empty="false">
                    <span
                      class="has-text-danger"
                      slot="noResult">G/L account not found.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>

        </article>
      </div>
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Invoices Detail</p>
          <label class="label">Invoices</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <tr>
                <th>Invoice No</th>
                <th>Debtor Name</th>
                <th class="has-text-left">Invoice Date</th>
                <th class="has-text-right">Total</th>
              </tr>
            </thead>
            <tfoot class="has-multiple-totals">
              <tr>
                <th class="has-text-right"
                  colspan="3">Total incl. GST</th>
                <th class="has-text-right has-text-success">{{ totalInvoiceBalance | formatCurrency($userInfo.locale) }}</th>
              </tr>
            </tfoot>
            <tbody v-if="innerValue.invoices.length > 0">
              <tr v-for="(item) in innerValue.invoices"
                :key="item.invoiceItemId">
                <td>{{ item.invoiceRef }}</td>
                <td>{{ item.assetName }}</td>
                <td>{{ $filters.formatDateLocale(item.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ item.totalIncGst | formatNumber($userInfo.locale) }}</td>
              </tr>
              <tr v-for="(invoice) in innerValue.invoices.filter(i => i.invoiceType == 8)"
                :key="invoice.invoiceId">
                <td>{{ invoice.invoiceRef }}</td>
                <td>{{ invoice.assetName }}</td>
                <td>{{ $filters.formatDateLocale(invoice.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ invoice.totalIncGst | formatNumber($userInfo.locale) }}</td>
              </tr>
            </tbody>
          </table>
          <label class="label">Payments</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <tr>
                <th>Date</th>
                <th>Debtor Name</th>
                <th class="has-text-left">Invoice No.</th>
                <th class="has-text-left">Payment Type</th>
                <th class="has-text-right">Paid</th>
              </tr>
            </thead>
            <tfoot class="has-multiple-totals">
              <tr>
                <th class="has-text-right"
                  colspan="4">Total Paid</th>
                <th class="has-text-right has-text-success">{{ totalPaid | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right"
                  colspan="4">Balance</th>
                <th class="has-text-right">
                  <span :class="balanceClass">{{ balance | formatCurrency($userInfo.locale) }}</span>
                </th>
              </tr>
            </tfoot>
            <tbody v-if="invoicePayments.length > 0">
              <tr v-for="(payment) in invoicePayments"
                :key="payment.invoicePaymentId">
                <td>
                  {{ $filters.formatDateLocale(payment.payDate, $userInfo.locale) }}
                </td>
                <td>{{ payment.assetName }}</td>
                <td>{{ payment.invoiceNo }}</td>
                <td>{{ Object.keys(invoicePaymentTypeEnum).find(k => invoicePaymentTypeEnum[k] == payment.paymentType) }}</td>
                <td class="has-text-right">{{ payment.amountPaid | formatNumber($userInfo.locale) }}</td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import VendorService from '@/views/vendor/VendorService'
import VueNumeric from '@/components/VueNumeric'
import _cloneDeep from 'lodash/cloneDeep'
import Guid from '@/components/Guid'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import _debounce from 'lodash.debounce'
import { mask } from 'vue-the-mask'
import { TheMask } from 'vue-the-mask'
import { EventHubTypes, InvoicePaymentTypes } from '@/enums'
import FactorInvoiceService from './FactorInvoiceService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'FactorInvoiceDetail',
  inject: ['$vv'],
  components: {
    Multiselect,
    VueNumeric,
    TheMask
  },
  directives: {
    mask
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    factorAmount: {
      type: Number,
      default: 0
    },
    disableChange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      vendors: [],
      vendorsName: [],
      factorPercent: 0,
      selectedVendor: {
        abn: '',
        id: '',
        name: '',
        totalRows: ''
      },
      selectedInvoiceDate: null,
      isVendorLoading: false,
      isGlBankAccountLoading: false,
      isGlAccountLoading: false,
      glAccountListCombo: null,
      glBankAccountListCombo: null,
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      invoiceDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    invoicePaymentTypeEnum() {
      return InvoicePaymentTypes
    },
    totalNormalInvoiceGst() {
      return this.innerValue.invoices.reduce((accumulator, currentValue) => accumulator + currentValue.totalIncGst, 0)
    },
    totalAdvanceBalance(){
      return this.innerValue.invoices.filter(i => i.invoiceType ==8).reduce((accumulator, currentValue) => accumulator + currentValue.totalIncGst, 0)
    },
    totalInvoiceBalance() {
      return this.totalNormalInvoiceGst + this.totalAdvanceBalance
    },
    enterReceiptPayment() {
      return [].concat.apply([],this.innerValue.invoices.map(x => x.payments))
    },
    totalPaid() {
      return this.invoicePayments.reduce((accumulator, currentValue) => accumulator + currentValue.amountPaid, 0)
    },
    invoicePayments() {
      return this.enterReceiptPayment
    },
    balance() {
      return this.totalInvoiceBalance - this.totalPaid
    },
    balanceClass() {
      if (this.balance > 0) {
        return 'has-text-success'
      } else if (this.balance < 0) {
        return 'has-text-danger'
      }
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedInvoiceDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.invoiceDate = null
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  async created() {
    console.log(this.value)
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.onReload()
    })
    this.getVendorsDropdown()
    this.getGLAccountDropdown()
    this.GetGLBankAccountsDropdown()
    this.selectedVendor = this.value.selectedVendor !== null ? this.value.selectedVendor : ''
    this.invoiceDate = new Date(`${this.innerValue.invoiceDate}`)
    this.selectedInvoiceDate = new Date(`${this.innerValue.invoiceDate}`)
    this.innerValue.netAmount = roundAwayFromZero(this.balance - this.innerValue.totalAmount)
    this.factorPercent = (((this.balance - this.innerValue.netAmount) / this.balance)) *100
  },
  mounted() {
    this.$v.detailGroup.$touch()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    calculateFactorFee() {
      this.innerValue.totalAmount = roundAwayFromZero(this.balance * this.factorPercent *0.01)
      this.innerValue.netAmount = roundAwayFromZero(this.balance - this.innerValue.totalAmount)
    },
    calculateFactorPercent() {
      this.innerValue.netAmount = roundAwayFromZero(this.balance - this.innerValue.totalAmount)
      this.factorPercent = (this.innerValue.totalAmount / this.balance) *100
    },
    async selectVendor(selected) {
      this.innerValue.selectedVendor = selected
      this.innerValue.vendorID = selected.id
      if (selected)
      {
        let abn = await VendorService.getAbn(selected.id)
        this.innerValue.abn = new Date().getTime()
        const vm = this
        setTimeout(function() {
          vm.innerValue.abn = abn
        })
      }
    },
    removeVendor() {},
    async getGLAccountDropdown() {
      this.isGlAccountLoading = true
      await VendorService.getGlAccountListComboProto().then(result => {
        this.glAccountListCombo = result
      })
      this.isGlAccountLoading = false
    },
    async GetGLBankAccountsDropdown() {
      this.isGlBankAccountLoading = true
      this.glBankAccountListCombo = await FactorInvoiceService.getGLBankAccountsDropdownProto(this.$userInfo.companyId)
      this.isGlBankAccountLoading = false
    },
    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find(c => c.id === this.innerValue.assetId)
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function(query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500)
  }
}
</script>