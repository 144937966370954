import { minLength, required } from 'vuelidate/lib/validators'
import { guidValidator } from '@/components/validators'
import { abnValidator } from '@/components/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.vendorID', 'entity.abn', 'entity.invoiceNo'],
    entity: {
      vendorID: {
        required,
        validGuid: guidValidator
      },
      abn: {
        required,
        minLength: minLength(11),
        validAbn: abnValidator
      },
      invoiceNo: {
        required
      }
    }
  }
}
